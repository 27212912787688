<template>
  <div class="myNewsCenter">
    <!-- 消息中心 -->
    <van-nav-bar :left-text="$t('meetingReservation.text1')" left-arrow @click-left="onClickLeft" :fixed="true">
    </van-nav-bar>
    <div class="filterBox">
      <div class="showPopup" @click="handshowDtPicker">
        {{ bd }} <svg-icon icon-class="dropdown" style="width: 0.65em;height: 0.65em;margin-left: 5px;"></svg-icon>
      </div>
      <div class="showTimeSelect"  @click="handShowSelectPickerTimeTime">
        {{ selectTimeTime[0] && selectTimeTime[1]?selectTimeTime[0]+'-'+selectTimeTime[1]:'空闲筛选' }}
        <van-icon @click.stop="clearSxTime" v-if="selectTimeTime[0] && selectTimeTime[1]" style="margin-left: 5px;" name="cross" />
        <svg-icon v-else icon-class="dropdown" style="width: 0.65em;height: 0.65em;margin-left: 5px;"></svg-icon>
      </div>
      <div class="showSelect" @click="handShowSelectPicker">
        {{ selectVal }} <svg-icon icon-class="dropdown"
          style="width: 0.65em;height: 0.65em;margin-left: 5px;"></svg-icon>
      </div>
    </div>
    <div class="content">
      <div class="content-box" :style="key == meetingRoomListData.length - 1 ? 'border-bottom:0' : ''"
        v-for="(value, key) in meetingRoomListData" :key="key" @click="visitDetails(value, key)">
        <div class="box-title">
          <div class="box-title-left">
            <strong>{{ value.name }}</strong>
            <span v-if="value.num_of_emp > 0">{{ value.num_of_emp }}{{ $t('meetingReservation.text31') }}</span>
          </div>
          <div class="box-title-right">
            {{ value.equipment }}
          </div>
        </div>
        <div class="box-list" style="border: 2px solid #ccc;">
          <div v-for="(item, index) in value.opt" v-show="item.show" :key="index" :class="[
            item.type == 1 ? 'huiyi-td-shixian' : 'huiyi-td-xuxian',
            item.active2 ? 'isSelect' : '',
            item.active1 ? 'isActive' : '',
            item.expire ? 'isExpire' : '',
            'gezi'
          ]"></div>
        </div>
        <div class="huiyi-table">
          <div class="huiyi-td td1">8</div>
          <div class="huiyi-td" style="font-size: 12px;">10</div>
          <div class="huiyi-td" style="font-size: 12px;">12</div>
          <div class="huiyi-td" style="font-size: 12px;">14</div>
          <div class="huiyi-td" style="font-size: 12px;">16</div>
          <div class="huiyi-td" style="font-size: 12px;">18</div>
          <div class="huiyi-td" style="font-size: 12px;">20</div>
          <div class="huiyi-td" style="font-size: 12px;">22</div>
        </div>
      </div>
    </div>
    <van-popup v-model="showSelectPicker" position="bottom">
      <van-picker show-toolbar :columns="selectPickerColumns" @confirm="onConfirmSelectPicker"
        @cancel="showSelectPicker = false" />
    </van-popup>
    <van-popup v-model="showSelectPickerTimeTime" position="bottom">
      <van-picker show-toolbar :columns="showSelectPickerTimeTimeColumns" @confirm="onConfirmSelectPickerTimeTime"
        @cancel="showSelectPicker = false" />
    </van-popup>
    <!-- 选择日期 -->
    <van-calendar v-model="showDtPicker" @confirm="onDtConfirm" />
    <van-popup v-model="show" closeable position="bottom" :style="{ height: '100%' }">
      <div class="detailsContainer">
        <div class="detailsContainerTit">
          <div style="
              width: 100%; 
              display: flex;
              align-items: center;
              flex-wrap: wrap;
            ">
            <div class="detailsContainerTitH3"><strong>{{ openOpt.name }}</strong></div>
            <div class="detailsContainerTitP"><span>{{ $t('meetingReservation.text2') }}</span></div>
          </div>
        </div>
        <div class="detailsContainerBox"
          :style="showSelectTimeBox ? 'height:calc(100% - 3rem)' : 'height:calc(100% - 1.66667rem)'">
          <div class="detailsBoxList" v-for="(value, key) in openData" :key="key">
            <div class="detailsBoxSpan" :style="{
              opacity: ((value.show || value.active) ? '1' : '0'),
              color: (value.active ? 'blue' : '#ccc')
            }">{{ value.time }}</div>
            <div class="detailsBoxLine" :data-time="value.time" @click="userClickTime($event, value, key)" :style="{
              borderTop: (value.select ? 'none' : (value.show ? '1px solid #ddd' : '1px dashed #ddd')),
              background: (value.select ? '#aed4fb' : (value.disable ? '#eee' : '#fff')),
            }">
              <span v-show="value.dim" style="color: blue;font-size: 12px;position: absolute;top: 5px;left: 5px;">{{
                value.ydr }} <span style="color: #333;">{{ $t('meetingReservation.text30') }}</span></span>
            </div>
          </div>
          <!-- 首次创建会议 -->
          <div class="userSelectTimeBox" v-show="showSelectTimeBox" :style="{
            top: slectObj.y,
            height: slectObj.h * 0.8 + 'rem'
          }">
            <div class="detailsBoxSpan" style="opacity:0;"></div>
            <div class="userSelectTimeBoxLine" @touchstart.stop="handleTouchStart" @touchmove.stop="handleTouchMove"
              @touchend.stop="handleTouchEnd">
              <div class="yuanA" @touchstart.stop="handleTouchStartA" @touchmove.stop="handleTouchMoveA"
                @touchend.stop="handleTouchEndA"></div>
              <div class="yuanA1" @touchstart.stop="handleTouchStartA" @touchmove.stop="handleTouchMoveA"
                @touchend.stop="handleTouchEndA"></div>
              <div class="yuanB" @touchstart.stop="handleTouchStartB" @touchmove.stop="handleTouchMoveB"
                @touchend.stop="handleTouchEndB"></div>
              <div class="yuanB1" @touchstart.stop="handleTouchStartB" @touchmove.stop="handleTouchMoveB"
                @touchend.stop="handleTouchEndB"></div>
            </div>
          </div>
        </div>
        <div class="detailsContainerFooter" v-if="showSelectTimeBox">
          <div class="footer-left">
            {{ timeObj }}
          </div>
          <van-button type="info" style="border-radius: 5px;" size="small"
            @click="ydHyClick($event, false, false, false, 'zengjia')" :loading="showYdContent"
            :loading-text="$t('meetingReservation.text3')">{{ $t('meetingReservation.text4')
            }}</van-button>
        </div>
      </div>
    </van-popup>
    <!-- 预定内容 -->
    <van-dialog v-model="showYdContent" :confirmButtonText="$t('meetingReservation.text5')"
      :cancelButtonText="$t('meetingReservation.text6')" :showCancelButton="true" className="ydContentVdialog"
      :before-close="confirmTj">
      <div class="ydnrContainer">
        <div class="ydnrBox">
          <div 
            v-for="(list, idx) in listData" 
            :key="idx" 
            class="ydnrList" 
            v-show="list.formshow"
            :class="{
              required: list.mustinput,
              fieldDisabled: list.isreadonly
            }
            "
          >
            <!-- 只读框 -->
            <van-field 
              placeholder="请输入" 
              v-if="list.formshow && !list.isread" 
              :disabled="true" 
              v-model="list.fieldvalue"
              :label="list.chnname" 
            />
            <van-field 
              placeholder="请输入" 
              v-if="list.formshow && list.maskeditstr == 'Password' && list.controltype == 'maskedit'"
              :disabled="true" 
              type="password" 
              v-model="list.fieldvalue" 
              :label="list.chnname" 
            />
            <!-- 文本框 -->
            <van-field 
              placeholder="请输入" 
              v-else-if="
                list.formshow &&
                list.controltype == 'edit' &&
                !list.ispoptree &&
                list.poptreename != 'emptree'
              " 
              :disabled="list.isreadonly" 
              v-model="list.fieldvalue" 
              :label="list.chnname" 
              @change="(val) => {
                valuechage(list.fieldvalue, list)
              }" 
            />
            <!-- 普通下拉框 测-->
            <van-field 
              placeholder="请输入" 
              v-else-if="
                list.formshow &&
                list.controltype === 'combobox' &&
                !list.ispoptree &&
                list.listsql != '@selectmonth' &&
                list.listsql != '@selectyear'
              " 
              :clickable="list.comdropstyle == 'lsEditList' ? false : true"
              :readonly="list.comdropstyle == 'lsEditList' ? false : true" 
              :disabled="list.isreadonly"
              v-model="list.fieldvalue" 
              :label="list.chnname" 
              @click="
                comboboxNoispoptreefocus(
                  list,
                  list.listsql,
                  list.controltype,
                  1
                )
              "
            >
              <template #right-icon>
                <!-- 右边下拉图标 -->
                <van-icon 
                  @click.stop="
                    comboboxNoispoptreefocus(
                      list,
                      list.listsql,
                      list.controltype,
                      2
                    )
                  " 
                  name="arrow-down" 
                />
              </template>
            </van-field>
            <!-- 表格下拉框  测-->
            <van-field 
              placeholder="请输入" 
              v-else-if="
                list.formshow &&
                list.controltype === 'lookupcombobox'
              " 
              clickable 
              readonly 
              :disabled="list.isreadonly" 
              v-model="list.fieldvalue" 
              :label="list.chnname" 
              @click="
                comboboxNoispoptreefocus(
                  list,
                  list.listsql,
                  list.controltype
                )
              "
            >
              <template #right-icon>
                <!-- 右边下拉图标 -->
                <van-icon name="arrow-down" />
              </template>
            </van-field>
            <!-- 下拉框弹出树(非员工树) 测-->
            <van-field 
              placeholder="请输入" 
              v-else-if="
                list.formshow &&
                list.controltype === 'combobox' &&
                list.ispoptree &&
                list.poptreename != 'emptree' &&
                list.listsql != '@selectmonth' &&
                list.listsql != '@selectyear'
              " 
              clickable 
              readonly 
              :disabled="list.isreadonly" 
              v-model="list.fieldvalue" 
              :label="list.chnname"
              @click="showTree(list)"
            >
              <template #right-icon>
                <!-- 右边下拉图标 -->
                <van-icon name="arrow-down" />
              </template>
            </van-field>
            <!-- 下拉框弹出树(员工树) 测 -->
            <van-field 
              placeholder="请输入" 
              v-else-if="
                list.formshow &&
                (list.controltype == 'combobox' || list.controltype == 'edit') &&
                list.ispoptree &&
                list.poptreename === 'emptree' &&
                list.listsql != '@selectmonth' &&
                list.listsql != '@selectyear'
              " 
              @change="(val) => {
                changeEmpname(val, list);
              }" 
              :disabled="list.isreadonly" 
              v-model="list.fieldvalue" 
              :label="list.chnname"
            >
              <template #right-icon>
                <!-- 右边下拉图标 -->
                <van-icon name="arrow-down" @click="showSelectJobNum(list)" />
              </template>
            </van-field>
            <!-- 复选框 测 -->
            <van-field 
              placeholder="请输入" 
              v-else-if="
                list.formshow &&
                list.controltype === 'checkbox'
              " 
              :disabled="list.isreadonly" 
              :label="list.chnname"
            >
              <template #input>
                <!-- 开关 -->
                <van-switch 
                  @change="(val) => {
                    valuechage(list.fieldvalue, list)
                  }" 
                  v-model="list.fieldvalue" 
                  :disabled="list.isreadonly" 
                  size="20" 
                />
              </template>
            </van-field>
            <!-- 颜色 测 -->
            <van-field 
              placeholder="请输入" 
              v-else-if="
                list.formshow &&
                list.controltype === 'colorcombobox'
              " 
              class="vanfield_checkgroup_box" 
              :disabled="list.isreadonly" 
              :label="list.chnname"
            >
              <template #input>
                <input @change="(val) => {
                  valuechage(list.fieldvalue, list)
                }" :disabled="!list.iswrite ||
                  list.isreadonly
                  " type="color" v-model="list.fieldvalue" id="colorPicker">
              </template>
            </van-field>
            <!-- 复选组 测试 -->
            <van-field  
              v-else-if="
                list.formshow &&
                list.controltype === 'checkgroup'
              " 
              class="vanfield_checkgroup_box" 
              :disabled="list.isreadonly" 
              :label="list.chnname"
            >
              <template #input>
                <van-checkbox-group v-model="list.fieldvalue" @change="(val) => {
                  valuechage(list.fieldvalue, list)
                }">
                  <van-checkbox shape="square" :name="value" v-for="(value, key) in list.checkgroupData" :key="key">
                    {{ value }}
                  </van-checkbox>
                </van-checkbox-group>
              </template>
            </van-field>
            <!-- 单选组 测 -->
            <van-field 
              v-else-if="
                list.formshow &&
                list.controltype === 'radiogroup'
              " 
              :disabled="list.isreadonly" 
              :label="list.chnname" 
              class="vanfield_radiogroup_box"
            >
              <template #input>
                <van-radio-group v-model="list.fieldvalue" @change="(val) => {
                  valuechage(list.fieldvalue, list)
                }">
                  <van-radio :name="value" v-for="(value, key) in list.checkgroupData" :key="key">
                    {{ value }}
                  </van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <!-- 多选下拉 -->
            <van-field 
              placeholder="请输入" 
              v-else-if="
                list.formshow &&
                list.controltype === 'selectcombobox'
              " 
              :disabled="list.isreadonly" 
              :label="list.chnname"
            >
              <template #input>
                <selectMultiple :columns="list.checkgroupData" v-model="list.fieldvalue">
                </selectMultiple>
              </template>
            </van-field>
            <!-- 年份 测 -->
            <van-field 
              placeholder="请输入" 
              v-else-if="
                list.formshow &&
                list.controltype === 'combobox' &&
                list.listsql == '@selectyear'
              " 
              clickable 
              readonly 
              :disabled="list.isreadonly" 
              v-model="list.fieldvalue" 
              :label="list.chnname"
              @click="settingYear(list)"
            >
              <template #right-icon>
                <!-- 右边下拉图标 -->
                <van-icon name="arrow-down" />
              </template>
            </van-field>
            <!-- 月份 测 -->
            <van-field 
              placeholder="请输入" 
              v-else-if="
                list.formshow &&
                list.controltype === 'combobox' &&
                list.listsql == '@selectmonth'
              " 
              clickable 
              readonly 
              :disabled="list.isreadonly" 
              v-model="list.fieldvalue" 
              :label="list.chnname"
              @click="settingYearMonth(list)"
            >
              <template #right-icon>
                <!-- 右边下拉图标 -->
                <van-icon name="arrow-down" />
              </template>
            </van-field>
            <!-- 日期框 测 -->
            <van-field 
              placeholder="请输入" 
              v-else-if="
                list.formshow &&
                list.controltype === 'datetime'
              " 
              clickable 
              readonly 
              :disabled="list.isreadonly" 
              v-model="list.fieldvalue" 
              :label="list.chnname"
              @click="settingDatetime(list)"
            >
              <template #right-icon>
                <!-- 右边下拉图标 -->
                <van-icon name="arrow-down" />
              </template>
            </van-field>
            <!-- 日期时间框 测-->
            <van-field 
              placeholder="请输入" 
              v-else-if="
                list.formshow &&
                list.controltype === 'longdatetime'
              " 
              clickable 
              readonly 
              :disabled="list.isreadonly" 
              v-model="list.fieldvalue" 
              :label="list.chnname"
              @click="settingLongDatetime(list)"
            >
              <template #right-icon>
                <!-- 右边下拉图标 -->
                <van-icon name="arrow-down" />
              </template>
            </van-field>
            <!-- 时间框 测 -->
            <van-field 
              placeholder="请输入" 
              v-else-if="
                list.formshow &&
                list.controltype === 'maskedit'
              " 
              clickable 
              readonly 
              :disabled="list.isreadonly" 
              v-model="list.fieldvalue" 
              :label="list.chnname"
              @click="settingTime(list)"
            >
              <template #right-icon>
                <!-- 右边下拉图标 -->
                <van-icon name="arrow-down" />
              </template>
            </van-field>
            <!-- 多行文本框 测-->
            <van-field 
              placeholder="请输入" 
              v-else-if="
                list.formshow &&
                list.controltype == 'memo'
              " 
              @change="(val) => {
                valuechage(list.fieldvalue, list)
              }" 
              type="textarea" 
              autosize 
              :disabled="list.isreadonly" 
              v-model="list.fieldvalue"
              :label="list.chnname" 
            />
          </div>
        </div>
      </div>
    </van-dialog>
    <!-- dateTime控件 -->
    <van-popup v-model="showDatetimePicker" position="bottom">
      <van-datetime-picker :title="selectFormdata.chnname" v-model="currentDatetime" type="date"
        @confirm="onDatetimeConfirm" @cancel="showDatetimePicker = false" />
    </van-popup>
    <!-- longdatetime控件 -->
    <van-popup v-model="showLongDatetimePicker" position="bottom">
      <van-datetime-picker :title="selectFormdata.chnname" v-model="currentLongDatetime" type="datetime"
        @confirm="onLongDatetimeConfirm" @cancel="showLongDatetimePicker = false" />
    </van-popup>
    <!-- maskedit控件 -->
    <van-popup v-model="showTimePicker" position="bottom">
      <van-datetime-picker :title="selectFormdata.chnname" v-model="currentTime" type="time" @confirm="onTimeConfirm"
        @cancel="showTimePicker = false" />
    </van-popup>
    <!-- 月份控件 -->
    <van-popup v-model="showYearMonthPicker" position="bottom">
      <van-datetime-picker :title="selectFormdata.chnname" v-model="currentYearMonth" type="year-month"
        @confirm="onYearMonthConfirm" @cancel="showYearMonthPicker = false" :formatter="formatter" />
    </van-popup>
    <!-- 年份控件 -->
    <van-popup v-model="showYearPicker" position="bottom" class="hideNfLLL">
      <van-datetime-picker :title="selectFormdata.chnname" v-model="currentYear" type="year-month"
        @confirm="onYearConfirm" @cancel="showYearPicker = false" :formatter="formatter2" />
    </van-popup>
    <!-- combobox控件 -->
    <van-popup v-model="showComboboxPicker" position="bottom">
      <van-picker :title="selectFormdata.chnname" show-toolbar value-key="dicvalue" :columns="comboboxChoices"
        @cancel="showComboboxPicker = false" @confirm="onComboboxConfirm" :default-index="comboboxDefaultIndex" />
    </van-popup>
    <!-- lookupCombobox控件 -->
    <van-popup v-model="showLookupComboboxPicker" position="bottom">
      <div style="width: 100%;overflow: hidden;padding: 10px;">
        <div class="table_box" style="overflow: auto;background: #fff;">
          <table ref="table" class="table">
            <tr class="top" v-for="(choice, i) in lookupcomboboxChoices.head" :key="i + '1'">
              <th v-for="(key, j) in choice.name" :key="j">
                <div style="margin: 0 auto;" class="title"
                  :style="choice.width[j] ? { width: choice.width[j] * 10 + 'px' } : 'min-width:90px'"
                  :c="choice.width[j]">
                  {{ key }}
                </div>
              </th>
            </tr>
            <tr @click="onLookupComboboxConfirm(choice, i)" class="cont"
              v-for="(choice, i) in lookupcomboboxChoices.data" :key="i"
              :class="choice.indexOf('isLookupcomboboxActive') != -1 ? 'isLookupcomboboxActiveBg' : ''">
              <td v-for="(k, idx) in choice" :key="idx" v-show="k != 'isLookupcomboboxActive'">
                {{ k }}
              </td>
            </tr>
          </table>
        </div>
        <div class="bottomPage" style="margin-top: 3px;">
          <van-pagination class="empPage" v-model="currentPage" :total-items="lookupcomboboxTotal" :show-page-size="3"
            force-ellipses @change="lookupcomboboxPageChange" />
        </div>
      </div>
    </van-popup>
    <!-- 选择人员树弹窗 -->
    <PopTree ref="popTreeNode" :whichTree="whichTreeName" module="1218" :multiseltree="isMultiselTree"
      @treeSelectValEvent="treeSelectValEvent"></PopTree>
    <!-- 多选员工 -->
    <empDataTable :singleSel="true" ref="refEmpDataTable" @choiceSelect="choiceSelect"></empDataTable>
    <van-calendar v-model="showYymmddbg" @confirm="onConfirmYymmddbg" :default-date="bdobj" />
    <van-popup v-model="showTmbgPicker" position="bottom">
      <van-datetime-picker type="time" v-model="tmbg" @confirm="onConfirmTmbg" @cancel="showTmbgPicker = false" />
    </van-popup>
    <van-popup v-model="showTmedPicker" position="bottom">
      <van-datetime-picker type="time" v-model="tmed" @confirm="onConfirmTmed" @cancel="showTmedPicker = false" />
    </van-popup>
  </div>
</template>

<script>
  import { Toast,Dialog,Notify } from 'vant';
  import { parseTime, hex2int, int2hex,paging } from '@/utils'
  import selectMultiple from '@/components/selectMultiple/index.vue'
  import empDataTable from '@components/empDataTable/index.vue'
  import PopTree from '@components/PopTree/index.vue'
  import {
    getListSource,
    getEmpInfo,
    getQueryEmpModuleNo,
  } from "@api/workFlow";
  import { 
    getMeetingRoomList,
    getMeetingBookDetail,
    getMeetingBookFieldStatus,
    getBeforeActionList,
    processBeforeAction,
    postModuleRecord,
    processAfterAction,
    getMeetingRoomAreaList
  } from '@api/m1218.js'
  export default {
    components: {
      selectMultiple,
      PopTree,
      empDataTable
    },
    data() {
      return {
        showDtPicker:false,                       // 选择预定日期 
        showSelectPicker:false,
        showSelectPickerTimeTime:false,
        showSelectPickerTimeTimeColumns:[
          {
            values: [
              '00:00','00:30','01:00','01:30','02:00','02:30','03:00','03:30','04:00','04:30','05:00','05:30','06:00','06:30',
              '07:00','07:30','08:00','08:30','09:00','09:30','10:00','10:30','11:00','11:30','12:00','12:30','13:00','13:30',
              '14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00','18:30','19:00','19:30','20:00','20:30',
              '21:00','21:30','22:00','22:30','23:00','23:30'
            ],
            defaultIndex: 0,
          },
          {
            values: [
              '00:00','00:30','01:00','01:30','02:00','02:30','03:00','03:30','04:00','04:30','05:00','05:30','06:00','06:30',
              '07:00','07:30','08:00','08:30','09:00','09:30','10:00','10:30','11:00','11:30','12:00','12:30','13:00','13:30',
              '14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00','18:30','19:00','19:30','20:00','20:30',
              '21:00','21:30','22:00','22:30','23:00','23:30'
            ],
            defaultIndex: 0,
          }
        ],
        selectPickerColumns:[],
        bd: parseTime(new Date(), '{y}-{m}-{d}'), // 选择预定日期 
        bdobj:new Date(),                         // 选择预定日期 
        userInfo: JSON.parse(localStorage.userInfo) || {},
        meetingRoomListData:[],                   // 会议显示列表
        huiyiTheme:[],
        show:false,                               // 会议可视化配置弹出框
        openOpt:{},                               // 选定的会议 
        detaiData:[],                             // 会议详情，用于修改内容的创建
        openData:[                                // 会议可视化配置的时间对象 
          { id:"1",time:"0:00",show:true,disable:false,num:0,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"2",time:"0:30",show:false,disable:false,num:0.5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"3",time:"1:00",show:true,disable:false,num:1,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"4",time:"1:30",show:false,disable:false,num:1.5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"5",time:"2:00",show:true,disable:false,num:2,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"6",time:"2:30",show:false,disable:false,num:2.5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"7",time:"3:00",show:true,disable:false,num:3,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"8",time:"3:30",show:false,disable:false,num:3.5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"9",time:"4:00",show:true,disable:false,num:4,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"10",time:"4:30",show:false,disable:false,num:4.5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"11",time:"5:00",show:true,disable:false,num:5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"12",time:"5:30",show:false,disable:false,num:5.5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"13",time:"6:00",show:true,disable:false,num:6,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"14",time:"6:30",show:false,disable:false,num:6.5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"15",time:"7:00",show:true,disable:false,num:7,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"16",time:"7:30",show:false,disable:false,num:7.5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"17",time:"8:00",show:true,disable:false,num:8,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"18",time:"8:30",show:false,disable:false,num:8.5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"19",time:"9:00",show:true,disable:false,num:9,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"20",time:"9:30",show:false,disable:false,num:9.5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"21",time:"10:00",show:true,disable:false,num:10,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"22",time:"10:30",show:false,disable:false,num:10.5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"23",time:"11:00",show:true,disable:false,num:11,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"24",time:"11:30",show:false,disable:false,num:11.5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"25",time:"12:00",show:true,disable:false,num:12,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"26",time:"12:30",show:false,disable:false,num:12.5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"27",time:"13:00",show:true,disable:false,num:13,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"28",time:"13:30",show:false,disable:false,num:13.5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"29",time:"14:00",show:true,disable:false,num:14,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"30",time:"14:30",show:false,disable:false,num:14.5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"31",time:"15:00",show:true,disable:false,num:15,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"32",time:"15:30",show:false,disable:false,num:15.5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"33",time:"16:00",show:true,disable:false,num:16,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"34",time:"16:30",show:false,disable:false,num:16.5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"35",time:"17:00",show:true,disable:false,num:17,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"36",time:"17:30",show:false,disable:false,num:17.5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"37",time:"18:00",show:true,disable:false,num:18,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"38",time:"18:30",show:false,disable:false,num:18.5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"39",time:"19:00",show:true,disable:false,num:19,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"40",time:"19:30",show:false,disable:false,num:19.5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"41",time:"20:00",show:true,disable:false,num:20,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"42",time:"20:30",show:false,disable:false,num:20.5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"43",time:"21:00",show:true,disable:false,num:21,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"44",time:"21:30",show:false,disable:false,num:21.5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"45",time:"22:00",show:true,disable:false,num:22,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"46",time:"22:30",show:false,disable:false,num:22.5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"47",time:"23:00",show:true,disable:false,num:23,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"48",time:"23:30",show:false,disable:false,num:23.5,active:false,select:false, ydr:"",dim:false, newautoid:0},
          { id:"49",time:"24:00",show:true,disable:false,num:24,active:false,select:false, ydr:"",dim:false, newautoid:0},
        ],
        clickSelectTime:0,  // 用户选择创建会议对象的时间段                              
        slectObj:{          // 可视化日期对象
          x:0,
          y:0,
          time:"",
          k:0,
          v:null,
          h:2
        },
        showSelectTimeBox:false, // 显示可视化日期
        startYA: 0, // 触摸开始的Y坐标
        endYA: 0, // 触摸结束的Y坐标
        touchingA: false, // 是否正在触摸
        startYB: 0, // 触摸开始的Y坐标
        endYB: 0, // 触摸结束的Y坐标
        touchingB: false, // 是否正在触摸
        startY: 0, // 触摸开始的Y坐标
        endY: 0, // 触摸结束的Y坐标
        touching: false, // 是否正在触摸
        showYdContent:false, // 预定内容弹出框
        showYymmddbg:false,  // 预定内容选择日期
        showTmbgPicker:false, // 预定内容选择时间
        showTmedPicker:false, // 预定内容选择时间
        listData:[],
        formstr:{},
        intarry: ["int", "smallint"], // sql整数类型
        floatarry: ["money", "float", "numeric"], // sql小数类型
        currentDatetime:new Date(),
        selectFormdata: {}, // 选中的字段（用于接收弹窗返回信息）
        showComboboxPicker:false,
        comboboxChoices:[],
        showLookupComboboxPicker:false,
        lookupcomboboxChoices:{
          head:[],
          data:[]
        },
        lookupcomboboxTotal: 0, // 进度条 总数
        currentPage: 1, // 当前页码
        pageSize: 10, // 每页多少条数据
        searchVal: "", // lookupcombobox模糊搜索值
        lookupcomboboxList: [],
        bigArray:[],
        showLongDatetimePicker:false,
        currentLongDatetime:new Date(),
        isMultiselTree:false,
        whichTreeName:"",
        comboboxDefaultIndex:0,  // 选择控件的默认值
        showTimePicker:false,
        currentTime:new Date(),
        showYearMonthPicker:false,
        currentYearMonth:new Date(),
        showYearPicker:false,
        currentYear:new Date(),
        customQueryForminfo: {}, //自定义窗体-构造查询窗体信息
        customQueryQueryparaminfo: [], //自定义窗体-构造顶部查询条件
        singleSel: true, // 多选是false，目前该版本只有单选
        tckStatus:"zengjia",
        logdetail: "", // 有修改的字段
        hasEXfieldlist: [], // 当前数据带出链已经带出过的字段
        showWrapStatus:true,       
        showDatetimePicker:false,  // 日期
        showObj:{},        // 修改情况下的预定内容
        showtype:1, //1是创建 2是修改
        showautoid:0, // 0是创建，其它是修改
        selectVal:"",
        selectTimeTime:["",""]
      }
    },
    created() {
      this.initData();
    },
    computed:{
      timeObj() {
        let str = this.openData[this.slectObj.k].time + " - " +  this.openData[this.slectObj.k + this.slectObj.h].time
        return this.bd+' '+str
      },
    },
    
    methods: {
      formatter(type, val) {
        if (type === 'year') {
          return `${val}年`;
        } else if (type === 'month') {
          return `${val}月`;
        }
        return val;
      },
      formatter2(type, val) {
        if (type === 'year') {
          return `${val}年`;
        } else if (type === 'month') {
          return ``;
        }
        return '';
      },
      async initData () {
        let pbt = parseTime(new Date(), '{h}:{i}') 
        let pet = parseTime(new Date(), '{h}:{i}') 
        this.selectTimeTime = ["",""];
        await this.getMeetingRoomDd();
        await this.getDataDetail()
      },
      async getMeetingRoomDd() {
        this.selectPickerColumns = []
        await getMeetingRoomAreaList({
          username:this.userInfo.username,
          langid: localStorage.getItem('langid')
        }).then(res=>{
          if(res && res.data.length > 0){
            const arr = res.data
            arr.forEach(element => {
              this.selectPickerColumns.push(element.area)
            })
            this.selectVal = this.selectPickerColumns[0]
          }
        })
      },
      onConfirmSelectPicker(v) {
        this.selectVal = v;
        this.showSelectPicker = false;
        this.getDataDetail(this.bd)
      },
      handShowSelectPickerTimeTime () {
        this.showSelectPickerTimeTime = true;
      },
      clearSxTime () {
        this.selectTimeTime = ["",""];
        this.getDataDetail(this.bd)
      },
      onConfirmSelectPickerTimeTime (v) {
        this.selectTimeTime = v;
        this.showSelectPickerTimeTime = false;
        this.getDataDetail()
      },
      // 预定内容创建或者修改
      ydHyClick (ee,p1,p2,p3,status) {
        this.tckStatus = status;
        const toast = Toast.loading({
          duration: 0,
          forbidClick: true,
          message: '请求中...',
        });
        this.yymmddbg = p1 || this.bd;
        this.tmbg = p2 || this.openData[this.slectObj.k].time
        this.tmed = p3 || this.openData[this.slectObj.k + this.slectObj.h].time
        getMeetingBookFieldStatus({
          autoid:status == 'zengjia'?0:this.showObj?this.showObj.autoid:0,
          mr_id:this.openOpt.autoid,
          yymmddbg:p1 || this.yymmddbg,
          tmbg:p2 || this.tmbg,
          tmed:p3 || this.tmed,
          username:this.userInfo.username,
          langid: localStorage.getItem('langid'),
          area:this.selectVal
        }).then(async res=>{
          this.listData = res.data;
          this.listData.forEach((item)=>{
            item.checkgroupData = []
            item.oldvalue = item.fieldvalue;
            item.haschange = false;
            item.newvalue = "";
            if(item.groupdata){
              if(item.controltype == 'checkgroup' || item.controltype == 'radiogroup'){
                item.checkgroupData = item.groupdata.split(",")
              }
              if(item.fieldvalue){
                if(item.controltype == 'checkgroup'){
                  item.fieldvalue = item.fieldvalue.split(",")
                }
                if(item.controltype == 'selectcombobox'){
                  let arr = item.fieldvalue.split(",");
                  item.fieldvalue = arr;
                }
              }else{
                if(item.controltype == 'checkgroup' || item.controltype == 'selectcombobox'){
                  item.fieldvalue = [];
                }else{
                  item.fieldvalue = "";
                }
              }
            }else{
              if(item.controltype == 'colorcombobox'){
                item.fieldvalue = "#" + int2hex(item.fieldvalue);
              }
              item.checkgroupData = []
            }
          })
          this.updateformstr();
          for(let list of this.listData){
            if(list.controltype == 'selectcombobox'){
              await getListSource({
                iswf: false,
                moduleno: '1218',
                fieldname: list.fieldname,
                username: this.userInfo.username,
                listsql: list.listsql,
                formstr: JSON.stringify(this.formstr),
                list_source_type: list.list_source_type,
                list_source_name: list.list_source_name,
                list_api_type: list.list_api_type,
                list_api_url: list.list_api_url,
                list_api_mode: list.list_api_mode,
                list_api_headerlist: list.list_api_headerlist
              }).then(res => {
                res.data.forEach((item) => {
                  list.checkgroupData.push({ value: item[Object.keys(item)[0]],key:item[Object.keys(item)[0]] });
                });
              })
            }
          }
          Toast.clear();
          this.showYdContent = true;
          this.$forceUpdate();
        })  
      },
      updateformstr () {
        this.listData.forEach(it => {
          if (it.fieldname && it.fieldname != 'undefined'){
            this.formstr[it.fieldname] =
              it.fieldvalue === null || it.fieldvalue === undefined
                ? "null"
                : it.fieldvalue === "" &&
                (it.controltype === "datetime" ||
                  this.floatarry.indexOf(it.fielddatatype) != -1 ||
                  this.intarry.indexOf(it.fielddatatype) != -1)
                ? "null"
                : it.fieldvalue === ""
                  ? it.fieldvalue
                  : it.controltype === "datetime"
                  ? parseTime(it.fieldvalue, "{y}-{m}-{d}")
                  : it.controltype === "checkbox"
                    ? it.fieldvalue === "是"
                    ? "true"
                    : it.fieldvalue === "否"
                      ? "false"
                      : it.fieldvalue.toString()
                    : it.controltype === "longdatetime"
                    ? parseTime(it.fieldvalue, '{y}-{m}-{d} {h}:{i}:{s}')
                    : it.fieldvalue && it.controltype === "colorcombobox"
                      ? hex2int(it.fieldvalue)
                      : it.fieldvalue.toString();
            if (
              it.maskeditstr === "Time" ||
              it.maskeditstr === "SelectTime" ||
              it.maskeditstr === "!90:00;1;_"
            ){
              it.fieldvalue =  it.fieldvalue && typeof it.fieldvalue == "string" ? it.fieldvalue.substr(0, 5) : it.fieldvalue;
              this.formstr[it.fieldname] = this.formstr[it.fieldname] && typeof this.formstr[it.fieldname] == "string"
                ? this.formstr[it.fieldname].substr(0, 5)
                : this.formstr[it.fieldname];
            }
            if (
              it.controltype === 'checkgroup'
            ){
              if(it.fieldvalue && typeof it.fieldvalue == 'object' && it.fieldvalue.length > 0){
                this.formstr[it.fieldname] = it.fieldvalue.join(",")
              }else{
                this.formstr[it.fieldname] = ""
              }
            }
            if (
              it.controltype === 'selectcombobox'
            ){
              if(it.fieldvalue && typeof it.fieldvalue == 'object' && it.fieldvalue.length > 0){
                this.formstr[it.fieldname] = it.fieldvalue.join(",")
              }else{
                this.formstr[it.fieldname] = ""
              }
            }
          }
        })
      },
      // select相关
      comboboxNoispoptreefocus (list, data, controltype,type) {
        if(type == 1 && list.comdropstyle == 'lsEditList'){
          return;
        }
        const selectListFormData = list.fieldvalue;
        const toast = Toast.loading({
          duration: 0,
          forbidClick: true,
          message: '请求中...',
        });
        this.updateformstr();
        this.selectFormdata = list;
        getListSource({
          iswf: false,
          moduleno: '1218',
          fieldname: list.fieldname,
          username: this.userInfo.username,
          listsql: data,
          formstr: JSON.stringify(this.formstr),
          list_source_type: list.list_source_type,
          list_source_name: list.list_source_name,
          list_api_type: list.list_api_type,
          list_api_url: list.list_api_url,
          list_api_mode: list.list_api_mode,
          list_api_headerlist: list.list_api_headerlist
        }).then(res => {
          Toast.clear();
          if (controltype === "combobox"){
            this.comboboxDefaultIndex = 0;
            this.showComboboxPicker = true;
            let tempdata = [];
            //普通下拉框只取第一个属性值，并且明明为dicvalue
            res.data.forEach((item) => {
              tempdata.push({ dicvalue: item[Object.keys(item)[0]] });
            });
            this.comboboxChoices = tempdata;
            if(selectListFormData){
              for(let i = 0; i < tempdata.length; i++){
                if(tempdata[i].dicvalue == selectListFormData){
                  this.comboboxDefaultIndex = i;
                  break;
                }
              }
            }
          } else if (controltype === "lookupcombobox"){
            this.lookupcomboboxList = []
            let tempdata2 = { head: [{ name: [], width: [] }], data: [] },
                li = [];
            if (res.data.length > 0){
              Object.keys(res.data[0]).forEach((item) => {
                tempdata2.head[0].name.push(item);
                tempdata2.head[0].width.push(item.toString().length);
              });
            }
            //表格下拉框需要新增表头数据
            for (let i = 0; i < res.data.length; i++){
              var tp = [];
              for (let j = 0; j < tempdata2.head[0].name.length; j++){
                let v = res.data[i][tempdata2.head[0].name[j]];
                  v =
                    v === null
                    ? ""
                    : v.toString().toLowerCase() === "true"
                      ? '是'
                      : v.toString().toLowerCase() === "false"
                      ? '否'
                      : v;
                  tp.push(v);
                if (v != null)
                {
                  let vl = v.toString().length;
                  tempdata2.head[0].width[j] =
                  tempdata2.head[0].width[j] >= vl?tempdata2.head[0].width[j]:vl;
                }
              }
              li.push(tp);
            }
            for (let j = 0; j < tempdata2.head[0].name.length; j++){
              let name = tempdata2.head[0].name[j];
              tempdata2.head[0].name[j] = name.indexOf("_") > -1 ? name.split("_")[0] : name;
            }
            this.lookupcomboboxList = li;
            let isLookupcomboboxActiveIndex = 0;
            this.currentPage = 1;
            if(selectListFormData){
              for(let i = 0; i < this.lookupcomboboxList.length; i++){
                if(selectListFormData == this.lookupcomboboxList[i][0]){
                  this.lookupcomboboxList[i].push("isLookupcomboboxActive")
                  isLookupcomboboxActiveIndex = i;
                }
              }
              this.currentPage = Math.ceil((isLookupcomboboxActiveIndex+1)/this.pageSize)
            }
            this.lookupcomboboxTotal = this.lookupcomboboxList.length;
            this.bigArray = paging(this.pageSize, this.lookupcomboboxList);
            tempdata2.data = this.bigArray[this.currentPage - 1];
            this.lookupcomboboxChoices = tempdata2;
            this.showLookupComboboxPicker = true;
          }
        })
      },
      // combobox控件
      onComboboxConfirm (obj) {
        this.setValue(this.selectFormdata.fieldname, obj.dicvalue)
        if (this.selectFormdata.isexitvalue){
          this.valuechage(obj.dicvalue, this.selectFormdata)
        }
        this.showComboboxPicker = false;
      },
      // lookupcombobox控件
      onLookupComboboxConfirm (obj,index) {
        this.setValue(this.selectFormdata.fieldname, obj[0])
        // 下拉字段带出过程
        if (this.selectFormdata.isexitvalue){
          this.valuechage(obj[0], this.selectFormdata)
        }
        this.showLookupComboboxPicker = false;
      },
      lookupcomboboxPageChange () {
        this.lookupcomboboxChoices.data = this.bigArray[this.currentPage - 1];
      },
      // 非员工树
      showTree(list) {
        this.whichTreeName = ''
        this.updateformstr();
        this.selectFormdata = list;
        this.isMultiselTree = list.multiseltree
        this.$refs.popTreeNode.module = '1218'
        this.$refs.popTreeNode.treename = list.poptreename
        this.$refs.popTreeNode.fieldname = this.selectFormdata.fieldname
        this.$refs.popTreeNode.editstate = 2
        this.$refs.popTreeNode.showEmpTreePop('数据')
      },
      // 工号自动带出
      changeEmpname (val, list) {
        let listval = list.fieldvalue
        if (
          list.ispoptree && list.poptreename == "emptree" && 
          (list.controltype == "combobox" || list.controltype == "edit")
        ){
          getEmpInfo({
            empcode: listval,
            moduleno: 201,
            username: this.userInfo.username,
          }).then((res) => {
            if (res.data.length === 0){
              //没有查到记录直接使用输入值进行带出
              listval = "";
              this.setValue(list.fieldname, listval);
              this.valuechage(listval, list);
            } else if (res.data.length === 1){
              //只有1笔记录，直接赋值
              listval = res.data[0].empcode;
              this.setValue(list.fieldname, listval);
              this.valuechage(listval, list);
            } else{
              this.selectFormdata = list;
              this.$refs.refEmpDataTable.getEmpData(res.data)
              this.$refs.refEmpDataTable.showEmpDataTablePicker = true;
            }
          })
          .catch((e) => {
            this.valuechage(listval, list);
          });
        } else {
          this.valuechage(listval, list);
        }
      },
      choiceSelect (obj) {
        this.setValue(this.selectFormdata.fieldname, obj[this.selectFormdata.fieldname]);
        this.valuechage(obj[this.selectFormdata.fieldname], this.selectFormdata);
      },
      showSelectJobNum (list) {
        this.whichTreeName = 'employee'
        this.updateformstr();
        getQueryEmpModuleNo({
          moduleno: '1218',
          username:this.userInfo.username,
          iswf: false,
          fieldname: list.fieldname,
        }).then((res) => {
          this.selectFormdata = list;
          this.quemoduleno = res.data[0].moduleno;
          this.$refs.popTreeNode.module = this.quemoduleno
          this.$refs.popTreeNode.treename = list.poptreename
          this.isMultiselTree = list.multiseltree
          this.$refs.popTreeNode.fieldname = this.selectFormdata.fieldname
          this.$refs.popTreeNode.editstate = 2
          this.$refs.popTreeNode.showEmpTreePop('数据')
        });
      },
      // 确定树的选择
      treeSelectValEvent (data) {
        this.$emit("setCanSave", true);
        // this.hasEXfieldlist = [];
        if (this.selectFormdata.multiseltree){
          //多选树
          const namelist = data.map((it) =>
            it.keyname.indexOf(" ") != -1?it.keyname.split(" ")[0]:it.keyname
          );
          const names = namelist.join(",");
          this.setValue(this.selectFormdata.fieldname, String(names));
          // 下拉字段带出过程
          if (this.selectFormdata.isexitvalue)
          {
            this.valuechage(names, this.selectFormdata); //判断数据带出
          }
        } else{
          //单选树
          this.setValue(
            this.selectFormdata.fieldname,
            data.keyname.indexOf(" ") != -1?data.keyname.split(" ")[0]:data.keyname
          );
          this.setValue(
            this.selectFormdata.popupdatefield,
            data[this.selectFormdata.popbackkeyid]
          );
          // 下拉字段带出过程
          if (this.selectFormdata.isexitvalue){
            this.valuechage(
              data.keyname.indexOf(" ") != -1?data.keyname.split(" ")[0]:data.keyname,
              this.selectFormdata
            ); 
          }
          //转化字段带出过程
          this.listData.forEach((it) => {
            if (
              it.isexitvalue &&
              it.fieldname === this.selectFormdata.popupdatefield
            ){
              this.valuechage(
                data[this.selectFormdata.popbackkeyid],
                it
              ); //判断数据带出
            }
          });
        }
      },
      // Datetime控件
      settingDatetime (list) {
        this.selectFormdata = list
        this.currentDatetime = list.formdata ? new Date(list.formdata) : new Date()
        this.showDatetimePicker = true;
      },
      // Datetime控件
      onDatetimeConfirm (time) {
        const v = parseTime(time, '{y}-{m}-{d}')
        this.setValue(this.selectFormdata.fieldname, v)
        if (this.selectFormdata.isexitvalue){
          this.valuechage(v, this.selectFormdata)
        }
        this.showDatetimePicker = false
      },
      // longDatetime控件
      settingLongDatetime (list) {
        this.selectFormdata = list
        this.currentLongDatetime = list.formdata ? new Date(list.formdata) : new Date()
        this.showLongDatetimePicker = true;
      },
      // longDatetime控件
      onLongDatetimeConfirm (time) {
        const v = parseTime(time, '{y}-{m}-{d} {h}:{i}:{s}')
        this.setValue(this.selectFormdata.fieldname, v)
        if (this.selectFormdata.isexitvalue){
          this.valuechage(v, this.selectFormdata)
        }
        this.showLongDatetimePicker = false
      },
      // time控件
      settingTime (list) {
        this.selectFormdata = list;
        const hours = this.padWithZeros(new Date().getHours(),2);
        const minute = this.padWithZeros(new Date().getMinutes(),2);
        this.currentTime = list.formdata ? new Date(list.formdata) : (hours+':'+minute)
        this.showTimePicker = true;
      },
      padWithZeros (number, length) {
        var str = "" + number;
        while (str.length < length) {
          str = "0" + str;
        }
        return str;
      },
      onTimeConfirm (time) {
        this.setValue(this.selectFormdata.fieldname, time)
        if (this.selectFormdata.isexitvalue){
          this.valuechage(time, this.selectFormdata)
        }
        this.showTimePicker = false
      },
      // 月份控件
      settingYearMonth (list) {
        this.selectFormdata = list
        this.currentYearMonth = list.formdata ? new Date(list.formdata) : new Date()
        this.showYearMonthPicker = true;
      },
      onYearMonthConfirm (time) {
        const v = parseTime(time, '{y}{m}')
        this.setValue(this.selectFormdata.fieldname, v)
        if (this.selectFormdata.isexitvalue){
          this.valuechage(v, this.selectFormdata)
        }
        this.showYearMonthPicker = false
      },
      // 年份控件
      settingYear (list) {
        this.selectFormdata = list
        this.currentYear = list.formdata ? new Date(list.formdata) : new Date()
        this.showYearPicker = true;
      },
      onYearConfirm (time) {
        console.log(this.selectFormdata.fieldname,'time')
        const v = new Date(time).getFullYear()
        this.setValue(this.selectFormdata.fieldname, v)
        if (this.selectFormdata.isexitvalue){
          this.valuechage(v, this.selectFormdata)
        }
        this.showYearPicker = false
      },
      // 数据带出
      ExitValue (data) {
        if (this.hasEXfieldlist.indexOf(data.fieldname) == -1){
          this.hasEXfieldlist.push(data.fieldname);
          getWFFormFieldExitValue(data).then((res) => {
            const data = res.data;
            data.forEach((item) => {
              if ("isreadonly" in item){
                // 如果带出过程携带isreadonly，则修改对应字段的isreadonly值
                if (item.isreadonly === '') item.isreadonly = null
                if (item.isreadonly != null) this.setReadOnly(item.fieldname, item.isreadonly, "isreadonly");
              }
              if ("mustinput" in item){
                // 如果带出过程携带mustinput，则修改对应字段的mustinput值
                if (item.mustinput === '') item.mustinput = null
                if (item.mustinput != null) this.setReadOnly(item.fieldname, item.mustinput, "mustinput");
              }
              if (item.fieldvalue != "<OrgFieldValue>"){
                this.setValue(item.fieldname, item.fieldvalue);
              }
            });
          });
        }
      },
      // 数据带出修改只读状态
      setReadOnly (n, isreadonly, key) {
        this.listData.forEach((i) => {
          if (i.fieldname === n){
            i[key] = isreadonly;
          }
        });  
      },
      // 表单change事件（判断是否触发数据带出）
      valuechage (val, list) {
        this.hasEXfieldlist = []
        list.haschange = true
        list.newvalue = val
        if (list.isexitvalue)
        {
          //启用数据带出
          let fun = this.ExitValue
          this.bulidExitValue(list.fieldname, val, fun)
        }
      },
      // 构造数据带出结构体，发起数据打出请求
      bulidExitValue (n, v, fun) {
        this.updateformstr();
        const totalformstr = JSON.stringify(this.formstr);
        const editstate =
        this.tckStatus === "zengjia" ? 1: 2;
        const data = {
          editstate: editstate,
          formautoid: this.formautoid,
          fieldname: n,
          fieldvalue: v,
          username: this.userInfo.username,
          formstr: totalformstr,
        };
        if (typeof fun === "function"){
          if(Object.prototype.toString.call(data.fieldvalue) === '[object Object]'){
            if('isTrusted' in data.fieldvalue){
            }else{
              fun(data);
            }
          }else{
            fun(data);
          }
        }
      },
      // 设置字段值（不触发数据带出）
      setValue (n, v, tar = "new") {
        this.listData.forEach((i) => {
          if (i.fieldname === n){
            let vv = v;
            if (i.fielddatatype === "bit"){
              const truearr = [1, "1", "true", "True", "TRUE", true];
              vv = truearr.indexOf(v) != -1 ? true : false;
            } else if (
              (i.controltype === "longdatetime" || i.controltype === "datetime") && vv !== null
            ){
              if(i.controltype === "datetime"){
                vv = parseTime(vv, "{y}-{m}-{d}")
              }
              if(i.datetime === "longdatetime"){
                vv = parseTime(vv, '{y}-{m}-{d} {h}:{i}:{s}')
              }
            } else if (i.controltype === "colorcombobox" && vv !== null){
              vv = "#" + int2hex(vv);
            } else if (i.controltype === "memo" && vv !== null){
              vv = vv.toString().replace(/\\n/g, "\n").replace(/\\r/g, "\r");
            } else if (i.controltype === "combobox" && vv !== null){
              if(i.listsql == '@selectyear'){
                // vv = parseTime(vv, "{y}")
              }else if(i.listsql == '@selectmonth'){
                // vv = parseTime(vv, "{y}-{m}")
              }
            }
            i.fieldvalue = vv;
            if (tar === "old"){
              i.oldvalue = vv;
              i.haschange = false;
              i.newvalue = "";
            } else if (tar === "new"){
              i.haschange = true;
              i.newvalue = vv;
            }
          }
        });
        this.$forceUpdate();
      },
      // 判断必填字段是否已经填写
      checkmustinput (b4multi) {
        let msg = false;
        for (let i = 0; i < this.listData.length; i++){
          let element = this.listData;
          for (let k = 0; k < element.length; k++){
            let i = element[k];
            if (i.mustinput && (i.fieldvalue === null || i.fieldvalue === ""))
            {
              // 多语言特殊处理字段
              Notify(i.chnname + " 必填！")
              msg = true;
              return msg;
            }
          }
        }
        return msg;
      },
      // 预定内容提交或修改
      async confirmTj (action, done) {
        if(action == 'cancel'){
          done();
        }else{
          if(this.checkmustinput()){
            done(false);
            return;
          }
          this.updateformstr();
          const moduleno = '1218'
          const res1 = await getBeforeActionList({
            actype: 0,
            moduleno,
            username: this.userInfo.username
          })
          const arr = res1.data // acprocname数组
          const editstate = this.showtype;
          const autoid = this.showautoid;
          let logdetail = "";
          if(autoid){
            this.formstr.autoid = autoid
          }
          let fromStr = JSON.stringify(this.formstr)
          for (const item of arr){
            const result = await processBeforeAction({
              acproname: item.acprocname,
              editstate: editstate,
              moduleno,
              username: this.userInfo.username,
              autoid,
              formstr: fromStr
            })
            if (result.data.length > 0 && result.data[0].info){
              Toast(result.data[0].info)
              return false;
            }else if(result.iserror == '1'){
              Toast(this.$t('meetingReservation.text26'));
              return false;
            }
          }
          const res2 = await postModuleRecord({
            moduleno,
            editstate: editstate,
            pcuserinfo: this.userInfo.username, // 用户名称,
            formstr: fromStr,
            logdetail: logdetail
          })
          const data2 = res2.data[0]
          if (res2.iserror === '0' && data2.info){
            Toast(data2.info)
            return false;
          }else if (res2.iserror === '1'){
            Toast(this.$t('meetingReservation.text26'))
            return false;
          }
          processAfterAction({
            actype: 0,
            editstate: editstate,
            moduleno,
            username: this.userInfo.username, // 用户名称,
            autoid: this.showautoid || data2.insid,
            formstr: fromStr
          }).then(res3=>{
            if(res3.iserror == "0"){
              Toast(this.$t('meetingReservation.text27'))
              done();
              this.show = false;
              this.getDataDetail(this.bd)
            }else{
              done(false);
              Toast(this.$t('meetingReservation.text26'))
              return false;
            }
          })
        }
      },
      handShowSelectPicker() {
        this.showSelectPicker = true;
      },
      // 显示下拉框
      handshowDtPicker () {
        this.showDtPicker = true
      },
      // 下拉框选择日期
      onDtConfirm (v) {
        this.bdobj = v; // 用于日期联动
        this.bd = parseTime(v, '{y}-{m}-{d}')
        this.getDataDetail(this.bd)
        this.showDtPicker = false
      },
      onConfirmYymmddbg (v) {
        this.yymmddbg = parseTime(v, '{y}-{m}-{d}')
        this.showYymmddbg = false;
      },
      onConfirmTmbg (v) {
        this.showTmbgPicker = false;
      },
      onConfirmTmed (v) {
        this.showTmedPicker = false;
      },
      onClickLeft() {
        if(this.$route.query.from == 'home'){
          this.$router.push('/home')
        }else{
          this.$router.push('/personCen')
        }
      },
      fillZero(str){
        var realNum;
        if (str < 10) {
          realNum = '0' + str;
        } else {
          realNum = str;
        }
        return realNum;
      },
      // 创建预定列表
      async getList (ddd) {
        console.log(this.bd,this.selectTimeTime,'fjksfhs')
        await getMeetingRoomList({
          username:this.userInfo.username,
          langid: localStorage.getItem('langid'),
          area:this.selectVal,
          yymmdd:this.bd?this.bd:"",
          bt:this.selectTimeTime?this.selectTimeTime[0]:"",
          et:this.selectTimeTime?this.selectTimeTime[1]:"",
        }).then(res=>{
          this.meetingRoomListData = res.data;
          const tody = new Date().getFullYear() + '' + this.fillZero(new Date().getMonth()+1) + '' + this.fillZero(new Date().getDate())
          const stime = ddd?String(ddd).replace(/-/g,""):0
          // 检验时间是否为今天，false代表是今天
          let jy = parseInt(tody) < parseInt(stime);
          const nowTimeHours = new Date().getHours();
          const nowTimeMinutes = new Date().getMinutes();
          const allTime = nowTimeHours + (nowTimeMinutes/60)
          for(let i = 0; i < this.meetingRoomListData.length; i++){
            this.meetingRoomListData[i].ydr = ""
            this.meetingRoomListData[i].opt = [
              { start:0,end:0.5,active1:false,active2:false,type:1,expire:false, data:null,show:false},
              { start:0.5,end:1,active1:false,active2:false,type:1,expire:false, data:null,show:false},
              { start:1,end:1.5,active1:false,active2:false,type:1,expire:false, data:null,show:false},
              { start:1.5,end:2,active1:false,active2:false,type:1,expire:false, data:null,show:false},
              { start:2,end:2.5,active1:false,active2:false,type:1,expire:false, data:null,show:false},
              { start:2.5,end:3,active1:false,active2:false,type:1,expire:false, data:null,show:false},
              { start:3,end:3.5,active1:false,active2:false,type:1,expire:false, data:null,show:false},
              { start:3.5,end:4,active1:false,active2:false,type:1,expire:false, data:null,show:false},
              { start:4,end:4.5,active1:false,active2:false,type:1,expire:false, data:null,show:false},
              { start:4.5,end:5,active1:false,active2:false,type:1,expire:false, data:null,show:false},
              { start:5,end:5.5,active1:false,active2:false,type:1,expire:false, data:null,show:false},
              { start:5.5,end:6,active1:false,active2:false,type:1,expire:false, data:null,show:false},
              { start:6,end:6.5,active1:false,active2:false,type:1,expire:false, data:null,show:false},
              { start:6.5,end:7,active1:false,active2:false,type:1,expire:false, data:null,show:false},
              { start:7.5,end:8,active1:false,active2:false,type:1,expire:!jy && allTime > 8, data:null,show:true},
              { start:8,end:8.5,active1:false,active2:false,type:2,expire:!jy && allTime > 8.5, data:null,show:true },
              { start:8.5,end:9,active1:false,active2:false,type:1,expire:!jy && allTime > 9, data:null,show:true },
              { start:9,end:9.5,active1:false,active2:false,type:2,expire:!jy && allTime > 9.5, data:null,show:true },
              { start:9.5,end:10,active1:false,active2:false,type:1,expire:!jy && allTime > 10, data:null,show:true },
              { start:10,end:10.5,active1:false,active2:false,type:2,expire:!jy && allTime > 10.5, data:null,show:true },
              { start:10.5,end:11,active1:false,active2:false,type:1,expire:!jy && allTime > 11, data:null,show:true },
              { start:11,end:11.5,active1:false,active2:false,type:2,expire:!jy && allTime > 11.5, data:null,show:true },
              { start:11.5,end:12,active1:false,active2:false,type:1,expire:!jy && allTime > 12, data:null,show:true },
              { start:12,end:12.5,active1:false,active2:false,type:2,expire:!jy && allTime > 12.5, data:null,show:true },
              { start:12.5,end:13,active1:false,active2:false,type:1,expire:!jy && allTime > 13, data:null,show:true },
              { start:13,end:13.5,active1:false,active2:false,type:2,expire:!jy && allTime > 13.5, data:null,show:true },
              { start:13.5,end:14,active1:false,active2:false,type:1,expire:!jy && allTime > 14, data:null,show:true },
              { start:14,end:14.5,active1:false,active2:false,type:2,expire:!jy && allTime > 14.5, data:null,show:true },
              { start:14.5,end:15,active1:false,active2:false,type:1,expire:!jy && allTime > 15, data:null,show:true },
              { start:15,end:15.5,active1:false,active2:false,type:2,expire:!jy && allTime > 15.5, data:null,show:true },
              { start:15.5,end:16,active1:false,active2:false,type:1,expire:!jy && allTime > 16, data:null,show:true },
              { start:16,end:16.5,active1:false,active2:false,type:2,expire:!jy && allTime > 16.5, data:null,show:true },
              { start:16.5,end:17,active1:false,active2:false,type:1,expire:!jy && allTime > 17, data:null,show:true },
              { start:17,end:17.5,active1:false,active2:false,type:2,expire:!jy && allTime > 17.5, data:null,show:true },
              { start:17.5,end:18,active1:false,active2:false,type:1,expire:!jy && allTime > 18, data:null,show:true },
              { start:18,end:18.5,active1:false,active2:false,type:2,expire:!jy && allTime > 18.5, data:null,show:true },
              { start:18.5,end:19,active1:false,active2:false,type:1,expire:!jy && allTime > 19, data:null,show:true },
              { start:19,end:19.5,active1:false,active2:false,type:2,expire:!jy && allTime > 19.5, data:null,show:true },
              { start:19.5,end:20,active1:false,active2:false,type:1,expire:!jy && allTime > 20, data:null,show:true },
              { start:20,end:20.5,active1:false,active2:false,type:2,expire:!jy && allTime > 20.5, data:null,show:true },
              { start:20.5,end:21,active1:false,active2:false,type:1,expire:!jy && allTime > 21, data:null,show:true },
              { start:21,end:21.5,active1:false,active2:false,type:2,expire:!jy && allTime > 21.5, data:null,show:true },
              { start:21.5,end:22,active1:false,active2:false,type:1,expire:!jy && allTime > 22, data:null,show:true },
              { start:22,end:22.5,active1:false,active2:false,type:2,expire:!jy && allTime > 22.5, data:null,show:true },
              { start:22.5,end:23,active1:false,active2:false,type:1,expire:false, data:null,show:false},
              { start:23,end:23.5,active1:false,active2:false,type:1,expire:false, data:null,show:false},
              { start:23.5,end:24,active1:false,active2:false,type:1,expire:false, data:null,show:false},
            ];
            this.meetingRoomListData[i].selectOpt = [];
            this.meetingRoomListData[i].btnStatus = true;
          }
        })
      },
      // 日期变化的时候 更新已选中
      async getDataDetail(ddd) {
        await this.getList(ddd);
        this.huiyiTheme = [];
        const d = new Date();
        const yy = d.getFullYear();
        const mm = d.getMonth();
        const dd = d.getDate();
        const yymmdd = yy + '-' + (mm+1 >= 10?''+(mm+1):'0'+(mm+1)) + '-' + (dd >= 10?''+dd:'0'+dd)
        await getMeetingBookDetail({
          yymmdd:ddd?ddd:yymmdd,
          username:this.userInfo.username,
          langid: localStorage.getItem('langid'),
          area:this.selectVal
        }).then(res=>{
          const data = res.data;
          this.detaiData = data;
          if(data && data.length > 0){
            for(let i = 0; i < data.length; i++){
              let beginT = 0;
              let endT = 0;
              if(data[i].tmbg){
                if(parseInt(data[i].tmbg.slice(3)) == 0){
                  beginT = parseInt(data[i].tmbg.slice(0,2))
                }else{
                  beginT = parseInt(data[i].tmbg.slice(0,2))+0.5
                }
              }
              if(data[i].tmed){
                if(parseInt(data[i].tmed.slice(3)) == 0){
                  endT = parseInt(data[i].tmed.slice(0,2))
                }else{
                  endT = parseInt(data[i].tmed.slice(0,2))+0.5
                }
              }
              // 如果某段时间存在会议，则列表数据的active2为true，标志为浅蓝色
              for(let j = 0; j < this.meetingRoomListData.length; j++){
                if(this.meetingRoomListData[j].name == data[i].roomnm){
                  this.meetingRoomListData[j].ydr = data[i].empname
                  let mkopt = this.meetingRoomListData[j].opt;
                  for(let k = 0; k < mkopt.length; k++){
                    for(let l = 0; l < (endT - beginT)*2; l++){
                      if(mkopt[k].start == beginT+l*0.5 && mkopt[k].end == beginT+l*0.5+0.5){
                        mkopt[k].active2 = true;
                        mkopt[k].data = data[i];
                      }
                    }
                  }
                }
              }
            }
            this.huiyiTheme =  data;
            this.$forceUpdate();
          }
        })
      },
      visitDetails (value,key) {
        this.show = true;
        this.openOpt = value;
        const nowTimeHours = new Date().getHours();
        const nowTimeMinutes = new Date().getMinutes();
        const allTime = nowTimeHours + (nowTimeMinutes/60)
        const vopt = value.opt;
        this.clickSelectTime = allTime;
        this.clearOpenDataActive();
        this.initOpen();
        this.clearOpenDataSelect();
        const tody = new Date().getFullYear() + '' + this.fillZero(new Date().getMonth()+1) + '' + this.fillZero(new Date().getDate())
        const stime = this.bd?String(this.bd).replace(/-/g,""):0
        let jy = parseInt(tody) < parseInt(stime);
        
        for(let i = 0; i < this.openData.length; i++){
          for(let j = 0; j < vopt.length; j++){
            // 把已安排会议的详情页即openData的数据设置为true，即显示为浅蓝色
            if(this.openData[i].num == vopt[j].start && vopt[j].active2){
              this.openData[i].select = true;
              this.openData[i].ydr = vopt[j].data.empname;
              this.openData[i].newautoid = vopt[j].data.autoid;
            }
          }
          // 如果不是今天，则全部设置为可修改
          if(jy){
            this.openData[i].disable = false;
          }else{
            if(this.openData[i].num < allTime - 0.5){
              this.openData[i].disable = true;
            }else{
              this.openData[i].disable = false;
            }
          }
        }
        for(let i = 0; i < this.openData.length; i++){
          if(i > 0){
            // 只显示一项已预定，有两种情况
            if(this.openData[i].select){
              // 一是两段会议不是黏着的时候，根据上下文是否存在select判断
              if(!this.openData[i-1].select){
                this.openData[i].dim = true;
              }else{
                // 二是两段会议黏着的时候，根据上下文的autoid是否一样去判断
                if(this.openData[i].newautoid && this.openData[i-1].newautoid && this.openData[i].newautoid != this.openData[i-1].newautoid){
                  this.openData[i].dim = true;
                }
              }
            }
          }
        }
      },
      // 初始化选定的日期对象
      initOpen() {
        this.slectObj.x = 0;
        this.slectObj.y = 0;
        this.slectObj.time = "";
        this.slectObj.k = 0;
        this.slectObj.v = null;
        this.slectObj.h = 2;
        this.showSelectTimeBox = false;
        this.startYA = 0;
        this.endYA = 0;
        this.touchingA = false;
        this.startYB = 0;
        this.endYB = 0;
        this.touchingB = false;
        this.startY = 0;
        this.endY = 0;
        this.touching = false;
        this.showtype = 1;
        this.showautoid = 0;
        this.showObj = {};
      },
      // 初始化可视化日期对象
      clearOpenDataActive () {
        for(let i = 0; i < this.openData.length; i++){
          this.openData[i].active = false;
        }
      },
      clearOpenDataSelect () {
        for(let i = 0; i < this.openData.length; i++){
          this.openData[i].select = false;
          this.openData[i].dim = false;
          this.openData[i].newautoid = 0;
        }
      },
      userClickTime (e,v,k) {
        const tody = new Date().getFullYear() + '' + this.fillZero(new Date().getMonth()+1) + '' + this.fillZero(new Date().getDate())
        const stime = this.bd?String(this.bd).replace(/-/g,""):0
        let jy = parseInt(tody) < parseInt(stime);
        // 如果是当天，且时间已过期，则提醒用户
        if(this.clickSelectTime - 0.5 > v.num && !jy){
          Toast(this.$t('meetingReservation.text28'));
        }else if(v.select){
          let arr = [];
          for(let  i = 0; i < this.detaiData.length; i++){
            if(this.detaiData[i].roomnm == this.openOpt.name){
              arr.push(this.detaiData[i])
            }
          }
          let vtime = 0;
          if(v.time){
            if(parseInt(v.time.slice(3)) == 0){
              vtime = parseInt(v.time.slice(0,2))
            }else{
              vtime = parseInt(v.time.slice(0,2))+0.5
            }
          }
          for(let i = 0; i < arr.length; i++){
            let beginT = 0;
            let endT = 0;
            if(arr[i].tmbg){
              if(parseInt(arr[i].tmbg.slice(3)) == 0){
                beginT = parseInt(arr[i].tmbg.slice(0,2))
              }else{
                beginT = parseInt(arr[i].tmbg.slice(0,2))+0.5
              }
            }
            if(arr[i].tmed){
              if(parseInt(arr[i].tmed.slice(3)) == 0){
                endT = parseInt(arr[i].tmed.slice(0,2))
              }else{
                endT = parseInt(arr[i].tmed.slice(0,2))+0.5
              }
            }
            // 访问详情页
            if(vtime >= beginT && vtime <= endT){
              this.showtype = 2
              this.showautoid = arr[i].autoid;
              this.showObj = arr[i]
              this.ydHyClick(null,arr[i].yymmddbg,arr[i].tmbg,arr[i].tmed,'xiugai')
              break;
            }
          }
        }else{
          // 判断是否存在足够的空间
          for(let i = 0; i < this.slectObj.h; i++){
            if(this.openData[i+k] && this.openData[i+k].select){
              Dialog({ message: this.$t('meetingReservation.text29') });
              return;
            }
          }
          this.clearOpenDataActive();
          this.showSelectTimeBox = true;
          this.slectObj.time = v.time;
          this.slectObj.v = v;
          // 如果用户在列表页尾部点击创建，则上移到适合的位置
          if((k+this.slectObj.h) >= 49){
            this.slectObj.y = (49 - this.slectObj.h)*0.8+'rem';
            this.openData[48].active = true;
            this.openData[48 - this.slectObj.h].active = true;
            this.slectObj.k = (48 - this.slectObj.h);
          }else{
            this.slectObj.k = k;
            v.active = true;
            this.slectObj.y = (k+1)*0.8+'rem';
            this.openData[k+this.slectObj.h].active = true;
          }
        }
      },
      handleTouchStartA(e) {
        if (e.touches.length === 1) {
          this.touchingA = true;
          this.startYA = e.touches[0].clientY;
        }
      },
      handleTouchMoveA(e) {
        if (this.touchingA) {
          // 阻止页面滚动
          e.preventDefault();
          this.endYA = e.touches[0].clientY;
          const deltaY = this.endYA - this.startYA;
          // 根据滑动方向做相应处理
          // (window.screen.width/750*60)*0.8的值越小，滑动越快
          if (Math.abs(deltaY) > (window.screen.width/750*60)*0.8) { 
            if (deltaY > 0) {
              // 向下滑动
              // console.log(this.openData[this.slectObj.k + 1],'this.openData[this.slectObj.k + 1]')
              if(this.slectObj.h == 1){
                console.log("无法下滑")
                return;
              }
              this.slectObj.h = (this.slectObj.h - 1);
              this.slectObj.k = (this.slectObj.k + 1);
              this.slectObj.y = (this.slectObj.k+1)*0.8+'rem';
              this.startYA = e.touches[0].clientY;
              this.openData[this.slectObj.k - 1].active = false;
              this.openData[this.slectObj.k].active = true;
            } else {
              // 向上滑动
              if(this.slectObj.k <= 0){
                console.log("无法上滑")
              }else{
                if(this.openData[this.slectObj.k - 1].disable || this.openData[this.slectObj.k - 1].select){
                  console.log("无法上滑")
                }else{
                  this.slectObj.k = (this.slectObj.k - 1);
                  this.slectObj.h = (this.slectObj.h + 1);
                  this.slectObj.y = (this.slectObj.k+1)*0.8+'rem';
                  this.startYA = e.touches[0].clientY;
                  this.openData[this.slectObj.k + 1].active = false;
                  this.openData[this.slectObj.k].active = true;
                  // console.log(,'this.slectObj.k')
                }
              }
            }
          }
        }
      },
      handleTouchEndA(e) {
        this.touchingA = false;
        this.startYA = 0;
        this.endYA = 0;
      },
      handleTouchStartB(e) {
        if (e.touches.length === 1) {
          this.touchingB = true;
          this.startYB = e.touches[0].clientY;
        }
      },
      handleTouchMoveB(e) {
        if (this.touchingB) {
          // 阻止页面滚动
          e.preventDefault();
          this.endYB = e.touches[0].clientY;
          const deltaY = this.endYB - this.startYB;
          // 根据滑动方向做相应处理
          if (Math.abs(deltaY) > (window.screen.width/750*60)*0.8) { 
            if (deltaY > 0) {
              // 向下滑动
              if((this.slectObj.k+this.slectObj.h) >= 48 || this.openData[(this.slectObj.k+this.slectObj.h)].select){
                console.log("无法下滑")
                return;
              }
              this.slectObj.h = (this.slectObj.h + 1);
              this.openData[this.slectObj.k + this.slectObj.h].active = true;
              this.openData[this.slectObj.k + this.slectObj.h - 1].active = false;
              this.startYB = e.touches[0].clientY;
            } else {
              // 向上滑动
              if(this.slectObj.h == 1){
                console.log("无法上滑")
                return;
              }
              this.slectObj.h = (this.slectObj.h - 1);
              this.startYB = e.touches[0].clientY;
              this.openData[this.slectObj.k + this.slectObj.h].active = true;
              this.openData[this.slectObj.k + this.slectObj.h + 1].active = false;
            }
          }
        }
      },
      handleTouchEndB(e) {
        this.touchingB = false;
        this.startYB = 0;
        this.endYB = 0;
      },
      handleTouchStart(e) {
        if (e.touches.length === 1) {
          this.touching = true;
          this.startY = e.touches[0].clientY;
        }
      },
      handleTouchMove(e) {
        if (this.touching) {
          // 阻止页面滚动
          e.preventDefault();
          this.endY = e.touches[0].clientY;
          const deltaY = this.endY - this.startY;
          // 根据滑动方向做相应处理
          if (Math.abs(deltaY) > (window.screen.width/750*60)*0.8) { // 10是一个阈值，用于判断是否是有效滑动
            if (deltaY > 0) {
              if((this.slectObj.k+this.slectObj.h) >= 48 || this.openData[(this.slectObj.k+this.slectObj.h)].select){
                console.log("无法下滑")
                return;
              }
              this.slectObj.k = this.slectObj.k + 1
              this.slectObj.y = (this.slectObj.k+1)*0.8+'rem';
              this.openData[this.slectObj.k + this.slectObj.h].active = true;
              this.openData[this.slectObj.k + this.slectObj.h - 1].active = false;
              this.openData[this.slectObj.k].active = true;
              this.openData[this.slectObj.k - 1].active = false;
              this.startY = e.touches[0].clientY;
            } else {
              if(this.slectObj.k <= 0){
                console.log("无法上滑")
                return;
              }
              if(this.openData[this.slectObj.k - 1].disable || this.openData[this.slectObj.k - 1].select){
                console.log("无法上滑")
                return;
              }
              this.slectObj.k = this.slectObj.k - 1
              this.slectObj.y = (this.slectObj.k+1)*0.8+'rem';
              if(this.slectObj.h >= 2){
                this.openData[this.slectObj.k + this.slectObj.h].active = true;
                this.openData[this.slectObj.k + this.slectObj.h + 1].active = false;
                this.openData[this.slectObj.k].active = true;
                this.openData[this.slectObj.k + 1].active = false;
              }else{
                this.openData[this.slectObj.k].active = true;
                this.openData[this.slectObj.k + 1].active = true;
                this.openData[this.slectObj.k + this.slectObj.h + 1].active = false;
              }
              this.startY = e.touches[0].clientY;
            }
          }
        }
      },
      handleTouchEnd(e) {
        this.touching = false;
        this.startY = 0;
        this.endY = 0;
      },
    }
  }
</script>

<style lang="less" scoped>
.myNewsCenter {
  height: 100vh;
  overflow: hidden;

  .van-nav-bar {
    background: #2b8df0;

    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }

      .van-nav-bar__text {
        color: #fff;
      }
    }
  }

  .ydnrList {
    /deep/.van-field__label {
      display: flex !important;
      align-items: center !important;
      flex-wrap: wrap !important;
      word-break: break-all !important;
    }
    /deep/.van-radio-group{
      .van-radio{
        margin: 10px 0;
      }
    }
    /deep/.van-checkbox-group{
      .van-checkbox{
        margin: 10px 0;
      }
    }
  }

  .fieldDisabled{
    .van-field--disabled{
      background: #efefef !important;
    }
    
    pointer-events: none;
  }
  
  .filterBox {
    margin-top: 1.22667rem;
    height: 88px;
    background: #fff;
    box-shadow: 3px 3px #ccc;
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .showTimeSelect{
    
    background: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-size: 32px;
  }
  .showPopup {
    padding: 10px 0 10px 28px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-size: 32px;
  }

  .showSelect {
    padding: 10px 28px 10px 0;
    background: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-size: 32px;

  }

  .content {

    overflow: auto;
    height: calc(100% - 177px);
    padding: 28px 28px 0;
    background: #fff;

    .content-box {
      width: 100%;
      padding-bottom: 30px;
      border-bottom: 1px solid #eee;
      margin-bottom: 30px;

      .box-title {
        display: flex;
        justify-content: space-between;
        align-items: end;
        margin-bottom: 36px;
        width: 100%;
        flex-wrap: nowrap;

        .box-title-left {
          display: flex;
          align-items: end;

          strong {
            font-size: 32px;
            font-weight: bold;
          }

          span {
            font-size: 20px;
            padding: 8px;
            border: 1px solid #ccc;
            display: block;
            border-radius: 8px;
            margin-left: 12px;
          }
        }

        .box-title-right {
          font-size: 20px;
          color: #666;
          max-width: 260px;
        }
      }

      .box-list {
        width: 100%;
        border: 2px solid #ccc;
        display: flex;
        flex-wrap: nowrap;
        border-radius: 4px;
      }

      .gezi {
        flex: 1;
        height: 50px;

        &.huiyi-td-shixian {
          border-right: 1px solid #ccc;
        }

        &.isExpire {
          background: #ddd;
        }

        &.isSelect {
          background: #bdd8f3 !important;
          // border-right: 1px solid #bdd8f3 !important;
        }
      }
    }

    .huiyi-table {
      width: 100%;
      display: flex;
      margin-top: 12px;
      position: relative;

      .huiyi-td {
        // flex: 1;
        font-size: 24px !important;
        width: 28px !important;
        text-align: center;
        position: relative;
        margin-left: 64px;
        color: #666;

        &.td1 {
          margin-left: 12px;
        }
      }
    }
  }
}

// 详情
.detailsContainer {
  width: 100%;
  height: 100%;

  .detailsContainerTit {
    height: 125px;
    width: 100%;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    box-shadow: 1px 2px 3px #ddd;

    .detailsContainerTitH3 {
      margin: 0;
      text-align: center;
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      strong {
        font-size: 32px;
      }
    }

    .detailsContainerTitP {
      margin: 0;
      text-align: center;
      width: 100%;
      margin-top: 7px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -5px;

      span {
        color: #666;
        font-size: 24px;
      }
    }
  }

  .detailsContainerBox {
    height: calc(100% - 125px);
    width: 100%;
    overflow: auto;
    padding-top: 60px;
    background: #f8f8f8;
    position: relative;

    .detailsBoxList {
      width: 100%;
      display: flex;
      height: 0.8rem;

      .detailsBoxSpan {
        font-size: 24px;
        color: #ccc;
        width: 100px;
        min-width: 100px;
        text-align: right;
        line-height: 1;
        position: relative;
        top: -12px;
        padding-right: 10px;
      }

      .detailsBoxLine {
        width: 100%;
        flex: 1;
        background: #fff;
        height: 100%;
        border-top: 1px solid #ddd;
        position: relative;
      }
    }

    .userSelectTimeBox {
      position: absolute;
      right: 0;
      min-height: 0.8rem !important;
      height: 1.6rem;
      z-index: 999999;
      width: 100%;
      display: flex;

      .detailsBoxSpan {
        font-size: 24px;
        color: #ccc;
        width: 100px;
        min-width: 100px;
        text-align: right;
        line-height: 1;
        position: relative;
        top: -12px;
        padding-right: 10px;
      }

      .userSelectTimeBoxLine {
        width: 100%;
        flex: 1;
        background: #fff;
        height: 100%;
        border: 1px solid blue !important;
        background: rgba(115, 125, 245, 0.3) !important;
        // border-right: 0 !important;
        position: relative;
        border-radius: 10px;

        .yuanA,
        .yuanA1 {
          width: 20px;
          height: 20px;
          background: #fff;
          border: 1px solid blue;
          border-radius: 20px;
          position: absolute;
          top: -11px;
          left: 50px;
        }

        .yuanA1 {
          left: 0;
          opacity: 0;
          width: 120px;
          height: 100px;
          top: -50px;
        }

        .yuanB,
        .yuanB1 {
          width: 20px;
          height: 20px;
          background: #fff;
          border: 1px solid blue;
          border-radius: 20px;
          position: absolute;
          bottom: -11px;
          right: 50px;
        }

        .yuanB1 {
          right: 0;
          opacity: 0;
          width: 120px;
          height: 100px;
          bottom: -50px;
        }
      }
    }
  }

  .detailsContainerFooter {
    position: fixed;
    bottom: 0;
    z-index: 99;
    left: 0;
    height: 100px;
    background: rgb(238, 238, 238);
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 24px;
    justify-content: space-between;

    .footer-left {
      font-size: 32px;
    }
  }
}

.ydnrContainer {
  width: 100%;
  height: 1000px;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;

  .ydnrBox {
    padding: 32px 32px 0;
  }

  .ydnrList {
    border-bottom: 1px solid #ccc;

    &.required {
      /deep/.van-cell__title {
        span {
          color: red !important;
        }
      }
    }
  }

  /deep/.van-cell {
    padding: 16px 0 !important;
  }
}

.ydContentVdialog {
  width: 90% !important;
  top: 50% !important;
}

.table_box {
  .table {
    width: 100%;
    border-spacing: 0px;

    tr {
      td:nth-child(1) {
        border-left: 2px solid #dcdfe6;
      }

      th,
      td {
        padding: 6px 10px;
        text-align: center;
        border-bottom: 2px solid #dcdfe6;
        border-right: 2px solid #dcdfe6;

        .comm_box {
          display: flex;
          justify-content: center;

          .boolean_box {
            .van-checkbox {
              justify-content: center;

              /deep/.van-checkbox__icon--checked {
                .van-icon {
                  color: #fff !important;
                  background-color: #1989fa !important;
                  border-color: #1989fa !important;
                }
              }

              /deep/.van-checkbox__icon--disabled .van-icon {
                background-color: #fff;
              }
            }
          }

          .input_box {
            width: 150px;

            .van-cell {
              font-size: 24px;
              border: 2px solid #d2d2d2;
              border-radius: 10px;
              padding: 8px 16px;
            }
          }
        }
      }
    }

    .top {
      position: sticky;
      top: 0;
      font-size: 28px;
      background: #e9f3fd;
      height: 64px;

      th:nth-child(1) {
        border-left: 2px solid #dcdfe6;
      }

      th {
        border-top: 2px solid #dcdfe6;
      }
    }

    .cont {
      height: 64px;
      font-size: 24px;
    }

    .cont:nth-child(2n-1) {
      background: #f5f5f5;
    }

    .nodata {
      td {
        font-size: 28px;
      }
    }
  }

  .isLookupcomboboxActiveBg {
    background: #9cd6ff !important;
  }
}
</style>